import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[maveInput]'
})
export class InputDirective {
  @HostBinding('class') class = 'w-full outline-none text-sm text-black-lighter focus:parent bg-transparent'

  constructor() { }

}
