import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { GanalModule } from './app/ganal.module';
import { environment } from './environments/environment';


if (environment.production) {

  enableProdMode();
}


platformBrowserDynamic()
  .bootstrapModule(GanalModule)
  .catch(err => console.error(err));
