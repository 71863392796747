<mave-modal #modal title="Summarize account">

  <h2>Form removed because of deprecated UI elements</h2>
  
  <div footer>
    <div class="flex justify-between">
      <mave-button label="Summarize" [loading]="isSubmitting"></mave-button>
      <mave-button label="Cancel" variant="secondary" (press)="closeModal()"></mave-button>
    </div>
  </div>

</mave-modal>
