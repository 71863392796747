<div class="group mb-3">
    <div class="p-1 px-2 block border border-gray bg-white rounded rounded-md outline-none group-hover:border-secondary-lighter" [ngClass]="{'border-red': hasErrors, 'group-hover:border-red': hasErrors }">
        <label class="block text-xs text-primary-dark font-semibold" [ngClass]="{'text-red': hasErrors }" for="name">{{ label }}</label>

        <small *ngIf="description !== ''">{{ description }}</small>

        <ng-content></ng-content>
    </div>

    <mave-validation [control]="control"></mave-validation>
</div>
