import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { IVersion } from '../interfaces/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private backend: BackendService) { }

  public getVersion(): Observable<IVersion> {
    return this.backend.getSingle("/api/v1/version");
  }

}
