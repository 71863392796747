import { HttpStatusCode } from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, Subscription, catchError, interval, throwError} from "rxjs";
import { BackendService } from 'src/app/lib/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PolledService {

  @Output() notifyOthers: EventEmitter<any> = new EventEmitter();

  pollInterval!: Subscription;

  constructor(private backend: BackendService) { }

  initializePolling() {
    this.getPolledData()
    .subscribe((allData: any) => {
      this.notifyOthers.emit(allData);
    })
  
    this.pollInterval = interval(10000).subscribe(() => {
      this.getPolledData()
      .subscribe((allData: any) => {
        this.notifyOthers.emit(allData);
      })
    });
  }
  
  stopPolling(){
    if (this.pollInterval === undefined) {
      throw new Error("No Polling Service Initialized");
    }

    this.pollInterval.unsubscribe();
  }

  public getPolledData(): Observable<any> {
    return this.backend.getAll("/api/v1/polled");
  }
}
