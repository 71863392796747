<div *ngIf="isOpen" class="fixed z-40 left-0 top-0 justify-center items-center w-screen h-screen bg-black-transparent">
  <div class="flex justify-center items-center w-full h-full">
    <mave-card class="w-full lg:w-3/4 xxl:w-1/3">
      <div class="flex justify-between items-center" header>
        <div>
          <mave-sub-title [text]="title"></mave-sub-title>
        </div>
        <div>
          <mave-action icon="close" type="secondary" (click)="closeModal()"></mave-action>
        </div>
      </div>

      <ng-content></ng-content>

      <div footer>
        <ng-content select="[footer]"></ng-content>
      </div>
    </mave-card>
  </div>
</div>
