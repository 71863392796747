import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ganal-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss']
})
export class NavbarItemComponent implements OnInit {
  @Input() link!: string;
  @Input() label: string = 'Link'

  constructor() { }

  ngOnInit(): void {
  }

}
