import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommentRequest } from '../../classes/comment-request';
import { ICommentThread } from '../../interfaces/icomment-thread';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';

@Component({
  selector: 'ganal-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @ViewChild('commentModal') commentModal!: CommentModalComponent;

  @Input() request!: CommentRequest;
  @Input() commentThread!: ICommentThread;
  @Input() commentType!: string;

  @Output() onModalClose: EventEmitter<any> = new EventEmitter();

  isRead: boolean = false;
  commentCount: string = '0';
  isLoadingCommentsOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.getIsRead();
    this.getCommentCount();
  }

  getIsRead() {
    if (this.commentThread === null || this.commentThread === undefined) {
      this.isRead = true;
    }

    if (this.commentThread?.commentCount <= 0) {
      this.isRead = true;
    }

    this.isRead = (this.commentThread && this.commentThread.isRead);
  }

  getCommentCount() {
    if (this.commentThread === null || this.commentThread === undefined) {
      this.commentCount = '0';
    }else{
      this.commentCount = '' + this.commentThread?.commentCount
    }
  }

  refreshBadges(){
    this.ngOnInit();
    this.onModalClose.emit();
  }

  openComments() {
    this.isLoadingCommentsOpen = true;

    this.commentModal.openCommentModal().subscribe((isLoading: boolean) => {
      this.isLoadingCommentsOpen = isLoading
    })
  }

}
