import { Component, Input, ViewChild } from '@angular/core';
import { PolledService } from '../../services/polled.service';
import { NgFor, NgIf } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterLink } from '@angular/router';
import { format, formatISO, parseISO } from 'date-fns';

@Component({
  selector: 'ganal-notifications',
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, OverlayPanelModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  @ViewChild('notificationPanel') notificationPanel!: OverlayPanel;

  feed: NotificationFeed = {
    count: 0,
    notifications: []
  };
  isLoading = true;
  lastRead!: Date

  constructor(private polledService: PolledService) {}

  ngOnInit() {
    this.isLoading = true;

    this.polledService.notifyOthers.subscribe((polledData) => {
      this.lastRead = parseISO(localStorage.getItem('notificationsReadDate') ?? '');
      this.feed = {count: 0, notifications: []},
      this.feed = polledData[3].data;
      this.isLoading = false;
    });
  }

  get hasUnread() {
    if (this.feed.notifications.length === 0) {
      return false;
    }

    return parseISO(this.feed.notifications[0].timestamp ?? '') > this.lastRead;
  }

  read(event: Event) {
    localStorage.setItem('notificationsReadDate', formatISO(new Date()));
    this.lastRead = parseISO(localStorage.getItem('notificationsReadDate') ?? '');
    this.notificationPanel.show(event);
  }

  getLink(notification: Notification) {
    if (notification.title === "Automations Prepared") {
      return "/status"
    }

    return "";
  }

}

interface NotificationFeed {
  count: number;
  notifications: Array<Notification>
}

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  timestamp: string;
}
