import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from './lib/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
  { path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule )},
  { path: 'ad', loadChildren: () => import('./ad/ad.module').then(m => m.AdModule) },
  { path: 'sync', loadChildren: () => import('./sync/sync.module').then(m => m.SyncModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'template', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule) },
  { path: 'status', loadChildren: () => import('./status/status.module').then(m => m.StatusModule) },
  { path: 'daily-reports', loadChildren: () => import('./daily-reports/daily-reports.module').then(m => m.DailyReportsModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'errors', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class GanalRoutingModule { }
