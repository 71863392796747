import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IComment} from "../../classes/comment";
import {CommentService} from "../../services/comment.service";
import {CommentRequest} from "../../classes/comment-request";
import {ICommentThread} from "../../interfaces/icomment-thread";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CommentServiceFactory} from "../../classes/comment-service-factory";
import { DeleteModalComponent } from 'src/app/mavejs/ui/delete-modal/delete-modal.component';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'ganal-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements OnInit {
  @ViewChild('deleteModal') deleteModal!: DeleteModalComponent;
  @ViewChild('commentModal') commentModal!: ModalComponent;
  @ViewChild('deleteThreadModal') deleteThreadModal!: DeleteModalComponent;

  @Input() request!: CommentRequest;
  @Input() commentThread!: ICommentThread;
  @Input() commentType!: string;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  commentForm!: UntypedFormGroup;
  comments!: Array<IComment>
  selectedComment!: IComment;
  selectedCommentText!: string;
  isLoadingCommentThread = false;
  isSavingComment = false;
  isDeletingComment = false;
  isDisabled = false;
  openmodalDisabled = false;
  saveError?: string;
  isLoadingCommentsSubject = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder, private commentService: CommentService) { }

  ngOnInit(): void {
    this.initCommentForm();
  }

  get text() {
    return this.commentForm.get("text");
  }

  public openCommentModal(): Observable<boolean> {
    this.isLoadingCommentsSubject.next(false);

    if(this.openmodalDisabled){
      return this.isLoadingCommentsSubject.asObservable();
    }

    this.isLoadingCommentsSubject.next(true);

    this.openmodalDisabled = true;

    this.commentService.flagAsRead(this.commentType, this.request.tenantId, this.commentThread.id)
        .subscribe(() => {
          this.commentThread.isRead = true;
          this.isLoadingCommentsSubject.next(false);
          this.loadComments();
          this.commentModal.openModal();
          this.openmodalDisabled = false;
          this.saveError = '';


        });

    return this.isLoadingCommentsSubject.asObservable();
  }

  loadComments() {
    this.isLoadingCommentThread = true;
    this.commentService.getCommentsByThreadId(this.commentType, this.request.tenantId, this.commentThread.id)
      .subscribe((comments: Array<IComment>) => {
        this.comments = comments;
        this.isLoadingCommentThread = false;
        this.initCommentForm();
        this.isDisabled = false;
      });
  }

  saveComment() {
    this.isDisabled = true;
    this.isSavingComment = true;
    new CommentServiceFactory(this.commentService, this.commentThread.id, this.request, this.text?.value, this.commentType,)
      .build()
      .subscribe(
        res => {
          this.saveError = '';
          this.isSavingComment = false;
          this.commentThread.commentCount++;
          this.loadComments();
        },
        err => {
          this.saveError = err.error.errors.text;
          this.isDisabled = false;
        });
  }

  openDeleteCommentModal(comment: IComment) {
    this.selectedComment = comment;
    this.selectedCommentText = comment.text;
    this.commentModal.closeModal();
    this.deleteModal.openModal();
  }

  deleteComment(comment: IComment){
    this.isDeletingComment = true;
    this.commentService.deleteComment(this.commentType, comment.id, this.request.tenantId)
      .subscribe(() => {
        this.isDeletingComment = false;
        this.commentThread.commentCount--;
        this.loadComments();
        this.deleteModal.closeModal();
        this.commentModal.openModal();
        this.saveError = '';
      })
  }

  initCommentForm(){
    this.commentForm = this.fb.group({
      text: ["", [Validators.required]]
    })
  }

  refreshDataAfterCloseModal(){
    this.modalClosed.emit();
  }

  saveKeyCombination(event: any){
    if (event.ctrlKey && event.key === "Enter") {
      if(!this.isDisabled){
        this.saveComment();
      }
    }
  }

  openDeleteCommentThread() {
    this.commentModal.closeModal();
    this.deleteThreadModal.openModal();
  }

  deleteCommentThread(){
    this.commentService.deleteCommentThread(this.commentType, this.request.tenantId, this.commentThread.id)
      .subscribe(() => {
        this.loadComments();
        this.deleteThreadModal.closeModal();
        this.commentModal.openModal();
        this.saveError = '';
        this.commentThread.commentCount = 0;
      })
  }

}
