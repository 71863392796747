import { Observable } from "rxjs";
import { CommentService } from "../services/comment.service";
import { CommentRequest } from "./comment-request";

export class CommentServiceFactory {
    commentService: CommentService;
    commentThreadId: number;
    request: CommentRequest;
    text: string;
    commentType: string;

    constructor(commentService: CommentService, commentThreadId: number, request: CommentRequest, text: string, commentType: string) {
        this.commentService = commentService;
        this.commentThreadId = commentThreadId;
        this.request = request;
        this.text = text;
        this.commentType = commentType;
    }

    public build(): Observable<any> {
        if (this.request.tenantId === this.request.modelId) {
          return this.commentService.saveComment(this.commentType, this.request.tenantId, this.text, this.commentThreadId, 0)
        }

        return this.commentService.saveComment(this.commentType, this.request.tenantId, this.text, this.commentThreadId, this.request.modelId)
    }
}
