import { Component, Input, OnInit } from '@angular/core';
import { NotificationService, Notification } from '../notification.service';
import { NotificationComponent } from '../notification/notification.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'mave-notification-area',
  standalone: true,
  imports: [NotificationComponent, NgForOf],
  templateUrl: './notification-area.component.html',
  styleUrls: ['./notification-area.component.scss']
})
export class NotificationAreaComponent implements OnInit {
  @Input() keepAlive: number = 1000; // ms

  notifications: Array<Notification> = []
  counter = 0;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.subscribe((notification: Notification) => {
      notification.id = this.counter;
      notification.type = notification.type ?? 'info'
      this.counter++;

      this.addIfNotDuplicate(notification);

      setTimeout(() => {
        this.notifications = this.notifications.filter(cNotification => cNotification.id !== notification.id)
      }, this.keepAlive)
    });
  }

  hasSameData(notification: Notification, compareNotification: Notification) {
    if (notification.title !== compareNotification.title) {
      return false;
    }

    if (notification.text !== compareNotification.text) {
      return false;
    }

    if (notification.type !== compareNotification.type) {
      return false;
    }

    return true;
  }

  containsNotification(notification: Notification) {
    for (let cNotification of this.notifications) {
      if (this.hasSameData(cNotification, notification)) {
        return true;
      }
    }

    return false;
  }

  addIfNotDuplicate(notification: Notification) {
    if (!this.containsNotification(notification)) {
      this.notifications.push(notification);
    }
  }

}
