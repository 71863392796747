import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from '../../../account/services/account.service';

@Component({
  selector: 'ganal-account-editable',
  templateUrl: './account-editable.component.html',
  styleUrls: ['./account-editable.component.scss']
})
export class AccountEditableComponent implements OnInit {
  @Input() accountId!: number;
  @Input() accountName!: string;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
  }

  changeAccountName(accountId: number, name: string) {
    this.accountService.updateAccountName(accountId, name).subscribe(() => { })
  }
}
