import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-empty',
  imports: [NgClass],
  standalone: true,
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit {
  @Input() title: string = 'Nothing here';
  @Input() subtitle: string = 'There are no results found here';
  @Input() small: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
