import { Component, OnInit } from '@angular/core';
import { IVersion } from '../../interfaces/version';
import { VersionService } from '../../services/version.service';

@Component({
  selector: 'ganal-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version: string = "0.0.0";

  constructor(private versionService: VersionService) { }

  ngOnInit(): void {
    this.versionService.getVersion().subscribe((version: IVersion) => {
      this.version = version.version ?? "0.0.0";
    })
  }

}
