
export class OptionalURLParams {

  params: {[key: string]: string};

  constructor(params: {[key: string]: string}) {
    this.params = params;
  }

  public getParams(): URLSearchParams {
    const cParams = new URLSearchParams();

    for (let param in this.params) {
      if (
        this.params[param] !== undefined &&
        this.params[param] !== "" &&
        this.params[param] !== null
      ) {
        cParams.set(param, this.params[param].toString());
      }
    }

    return cParams;
  }

}
