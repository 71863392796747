<div class="relative">
  <div
  *ngIf="showCount"
  [ngClass]="{ 'left-0': (position === 'left'), 'right-0': (position !== 'left'), 'h-6': !small, 'w-6': !small, 'h-4': small, 'w-4': small }"
  class="left-24 top-2 absolute lg:top-0 lg:-mt-2 flex bg-primary justify-center items-center rounded-full p-1 text-white text-xs font-bold">
    {{ text }}
  </div>

  <div class="flex flex-row">
    <div
      *ngIf="showGreenIndicator"
      [ngClass]="{ 'left-0': (position === 'left'), 'h-6': !small, 'w-6': !small, 'h-4': small, 'w-4': small }"
      class="absolute top-0 -mt-2 flex bg-green justify-center items-center rounded-full p-1 text-white text-xs font-bold">

    </div>
    <div
      *ngIf="showOrangeIndicator"
      [ngClass]="{ 'left-0': (position === 'left'), 'h-6': !small, 'w-6': !small, 'h-4': small, 'w-4': small }"
      class="absolute top-0 -mt-2 flex bg-orange-light justify-center items-center rounded-full p-1 text-white text-xs font-bold animate-bounce">
      !
    </div>
    <div
      *ngIf="showRedIndicator"
      [ngClass]="{ 'left-0': (position === 'left'), 'left-7': showGreenIndicator || showOrangeIndicator, 'h-6': !small, 'w-6': !small, 'h-4': small, 'w-4': small }"
      class="absolute top-0 -mt-2 flex bg-red-dark justify-center items-center rounded-full p-1 text-white text-xs font-bold animate-bounce">
      !
    </div>
  </div>
  <ng-content></ng-content>
</div>
