import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ButtonComponent } from '../../form/button/button.component';

@Component({
  selector: 'mave-delete-modal',
  imports: [ModalComponent, ButtonComponent],
  standalone: true,
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  @Input() open: boolean = false;
  @Input() title: string = 'Delete Entity'
  @Input() message: string = 'Do you really want to delete this entity?'

  @Output() delete = new EventEmitter();

  @ViewChild('modal') modal!: ModalComponent;

  isDisabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.modal.openModal();
  }

  closeModal() {
    this.modal.closeModal();
    this.isDisabled = false;
  }

  deleteAction() {
    this.isDisabled = true;
    this.delete.emit();
  }

}
