<div>
  <div class="group absolute">
    <div
      class="relative z-50 p-2 -mt-8 rounded-md bg-black text-white text-sm transition w-max"
      [ngClass]="{'hidden': (!isOpen || forceHide), 'opacity-0': (!isOpen || forceHide), 'group-hover:opacity-100': isOpen,
      'left-5':direction === 'right', 'right-full':direction === 'left', 'top-8':direction === 'left', 'mr-2':direction === 'left' }">
      {{ text }}
    </div>
  </div>
  <div class="cursor-pointer" (mouseenter)="isOpen = true" (mouseleave)="isOpen = false">
    <ng-content></ng-content>
  </div>
</div>
