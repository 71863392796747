import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';

@Component({
  selector: 'ganal-summarize-modal',
  templateUrl: './summarize-modal.component.html',
  styleUrls: ['./summarize-modal.component.scss']
})
export class SummarizeModalComponent implements OnInit {
  @ViewChild('modal') modal!: ModalComponent;

  isSubmitting = false;

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.modal.openModal();
  }

  closeModal() {
    this.modal.closeModal();
  }

}
