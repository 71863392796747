import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SummarizeModalComponent } from 'src/app/lib/components/summarize-modal/summarize-modal.component';
import { ISynchronizationStatus } from 'src/app/sync/interfaces/synchronization-status';
import { SyncService } from 'src/app/sync/services/sync.service';
import { AuthService } from '../../services/auth.service';
import {PolledService} from "../../services/polled.service";

@Component({
  selector: 'ganal-refresh-metrics',
  templateUrl: './refresh-metrics.component.html',
  styleUrls: ['./refresh-metrics.component.scss']
})
export class RefreshMetricsComponent implements OnInit {
  @ViewChild('summarizeModal') summarizeModal!: SummarizeModalComponent;

  isSyncing = false;
  latestSynchronization: any

  items: MenuItem[] = [
    {label: 'Summarize', icon: 'pi pi-summary', command: () => this.summarizeModal.openModal() }
  ];

  constructor(private syncService: SyncService, private authService: AuthService, private confirmationService: ConfirmationService, private polledService: PolledService) { }

  ngOnInit(): void {
    this.getPolledData();
    setInterval(() => {
      if (!this.authService.hasAuthorizationToken()) {
        return;
      }
    }, 10000)
  }

  getPolledData(){
    this.polledService.notifyOthers.subscribe((polledData: any) => {
      this.latestSynchronization = polledData[0].data.lastRefreshTimeStamp;
      this.isSyncing = polledData[0].data.refreshing;
    })
  }

  syncAllData() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to resync data of today?',
      accept: () => {
        this.syncService.syncAllDataToday().subscribe(() => {
          this.isSyncing = true
        });
      }
    })
  }
}
