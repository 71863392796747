import { Account, IAccount } from "src/app/account/interfaces/account";
import { IGraphFormatter } from "./igraph-formatter";

export class GraphCostFormatterBuilder implements IGraphFormatter {
  private account: IAccount;
  private value: any;

  constructor(value: any, account: IAccount) {
    this.value = value;
    this.account = account;
  }

  public format(): string {
    let valueInK = this.value / 1000000000

    return Account.getCurrencyCode(this.account) + valueInK.toFixed(1) + 'K';
  }

}



