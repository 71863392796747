import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { GanalRoutingModule } from './ganal-routing.module';
import { SessionInterceptor } from './lib/interceptors/session-interceptor';
import { HomeComponent } from './home.component';
import { ForbiddenInterceptor } from './lib/interceptors/forbidden-interceptor';
import { UnauthorizedInterceptor } from './lib/interceptors/unauthorized-interceptor';
import { NotFoundInterceptor } from './lib/interceptors/not-found-interceptor';
import { GanalComponent } from './ganal.component';
import { CardModule } from 'primeng/card';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ChartModule } from 'primeng/chart';
import { CommentModule } from './comment/comment.module';
import { LibModule } from './lib/lib.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationAreaComponent } from './mavejs/util/notification-area/notification-area.component';
import { CardComponent } from './mavejs/base/card/card.component';
import { LoaderComponent } from './mavejs/http/loader/loader.component';
import { DatatableComponent } from './mavejs/data/datatable/datatable.component';
import { TooltipComponent } from './mavejs/base/tooltip/tooltip.component';
import { SubTitleComponent } from './mavejs/text/sub-title/sub-title.component';
import { ActionComponent } from './mavejs/base/action/action.component';
import { DatatableColumnComponent } from './mavejs/data/datatable-column/datatable-column.component';
import { DatatableRowDirective } from './mavejs/data/datatable-row.directive';
import { DatatableHeaderComponent } from './mavejs/data/datatable-header/datatable-header.component';
import { DatatableColumnMonoComponent } from './mavejs/data/datatable-column-mono/datatable-column-mono.component';
import { serverErrorInterceptor } from './lib/interceptors/server-error.interceptor';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    HomeComponent,
    GanalComponent
  ],
  imports: [
    // Components
    NotificationAreaComponent,
    CardComponent,
    LoaderComponent,
    DatatableComponent,
    TooltipComponent,
    SubTitleComponent,
    ActionComponent,
    DatatableColumnComponent,
    DatatableRowDirective,
    DatatableHeaderComponent,
    DatatableColumnMonoComponent,


    // Modules
    CommonModule,
    BrowserModule,
    ChartModule,
    HttpClientModule,
    CardModule,
    NoopAnimationsModule,
    CommentModule,
    LibModule,
    GanalRoutingModule,
    TooltipModule,
    ConfirmDialogModule
  ],
  providers: [
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true },
    provideHttpClient(
      withInterceptors([
        serverErrorInterceptor
      ])
    )
  ],
  bootstrap: [GanalComponent]
})

export class GanalModule { }
