<mave-delete-modal #deleteModal title="Delete Comment"
                   [message]="'Do you really want to delete comment?: ' + selectedCommentText"
                   (delete)="deleteComment(selectedComment)">
</mave-delete-modal>

<mave-delete-modal #deleteThreadModal title="Delete Comment Thread"
                   [message]="'Do you really want to delete this comment thread?'"
                   (delete)="deleteCommentThread()">
</mave-delete-modal>

<mave-modal [title]="'Comments'" #commentModal (modalClosed)="refreshDataAfterCloseModal()" id="comment-modal">
  <div class="p-3 font-bold text-secondary">
    <div class="bg-white -mt-4 mb-1">
      <form [formGroup]="commentForm" (submit)="saveComment()" class="flex flex-row">
        <mave-textbox name="text" [control]="text" class="flex-1 mr-4">
          <textarea formControlName="text" cols="30" rows="1" maveInput (keydown)="saveKeyCombination($event)"></textarea>
        </mave-textbox>
        <!--  DESKTOP  -->
        <div class="hidden lg:block">
          <mave-button icon="send" (press)="saveComment()" class="flex-none" [disabled]="isDisabled"></mave-button>
        </div>
        <div class="hidden lg:block ml-4" *ngIf="comments && comments.length > 0">
          <mave-button label="Delete All" (press)="openDeleteCommentThread()" class="flex-none" [disabled]="isDisabled" variant="danger"></mave-button>
        </div>
        <!-- -->
      </form>
    </div>
    <div class="-mt-2 mb-2">
      <span class="text-red">{{saveError}}</span>
    </div>
    <mave-loader [isLoading]="isLoadingCommentThread"></mave-loader>
    <div *ngIf="!isLoadingCommentThread" class="max-h-96 overflow-y-auto">
      <div *ngFor="let comment of comments" class="p-3 rounded rounded-lg mb-2 relative" [ngClass]="{ 'bg-secondary-lightest': comment.isYou, 'bg-gray-lightest': !comment.isYou }">
        <div class="flex flex-row items-center font-semibold">
          <div class="text-sm text-secondary-lighter mr-2 w-22">{{ comment.createdAt | date : 'd MMM - HH:mm' : 'Europe/Brussels' }}</div>
          -
          <div class="text-sm ml-2">{{ comment.authorName }}</div>
          <mave-action icon="delete" type="delete" class="inline-block absolute top-2 right-2" (click)="openDeleteCommentModal(comment)"></mave-action>
        </div>
        <div class="text-sm font-semibold text-black pb-1 pr-10 mt-2 text-justify whitespace-normal">{{ comment.text }}</div>
      </div>
    </div>
    <mave-empty class=""
                *ngIf="(comments === undefined || comments.length === 0) && isLoadingCommentThread == false"
                title="No Comments"
                [small]="true">
    </mave-empty>
  </div>

  <!--  MOBILE  -->
  <div footer class="lg:hidden">
    <div class="flex flex-row items-center justify-center">
      <div class="-mt-2 mr-4" *ngIf="comments && comments.length > 0">
        <mave-button label="Delete All" (press)="openDeleteCommentThread()" class="flex-none" [disabled]="isDisabled" variant="danger"></mave-button>
      </div>
      <div class="-mt-2">
        <mave-button icon="send" (press)="saveComment()" class="flex-none" [disabled]="isDisabled"></mave-button>
      </div>
    </div>
  </div>
  <!-- -->
</mave-modal>
