import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {User} from 'src/app/account/views/permissions/permissions.component';
import {StatusService} from 'src/app/status/services/status.service';
import {UserService} from 'src/app/user/services/user.service';
import {IAlert} from '../../interfaces/ialert';
import {AlertService} from '../../services/alert.service';
import {AuthService} from '../../services/auth.service';
import {PolledService} from "../../services/polled.service";
import {Menu} from "primeng/menu";

@Component({
  selector: 'ganal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() pageTitle?: string;

  @ViewChild('desktopMenu') desktopMenu!:  Menu;
  @ViewChild('userMenu') userMenu!:  Menu;

  constructor(private statusService: StatusService, private router: Router, private alertService: AlertService, private userService: UserService, private auth: AuthService, private polledService: PolledService) {
  }

  totalCount = 0;
  totalCountString: string = '';
  showGreenIndicator = false;
  showOrangeIndicator = false;
  showRedIndicator = false;
  csvCount = 0;
  alert: IAlert = {message: ""};
  user!: User;
  items!: MenuItem[];
  desktopMenuItems!: MenuItem[];
  showMenu: boolean = false;
  showMenuDekstop: boolean = false;

  ngOnInit(): void {
    this.getUser();
    this.setDesktopMenuItems();
    this.getPolledData();
    setInterval(() => this.getPolledData(), 20000);
  }

  getUser() {
    this.userService.getSelf().subscribe((user: User) => {
      this.user = user;

      if (this.user.isSuperAdmin) {
        this.items = [
          {
            label: 'Developer Mode',
            items: [
              {label: 'Api Access', routerLink: '/admin/api_keys'},
            ]
          },
          {
            label: 'User',
            items: [

              {label: 'User Management', routerLink: '/account/permissions'},
              {label: 'User Profile', routerLink: '/user/profile'}
            ]
          },
          {
            label: 'Actions',
            items: [
              {label: 'Logout', icon: 'pi pi-sign-out', command: () => this.logout()}
            ]
          }
        ]
      } else {
        this.items = [
          {
            label: 'User',
            items: [
              {label: 'User Management', routerLink: '/account/permissions'},
              {label: 'User Profile', routerLink: '/user/profile'}
            ]
          },
          {
            label: 'Actions',
            items: [
              {label: 'Logout', icon: 'pi pi-sign-out', command: () => this.logout()}
            ]
          }
        ]
      }
    })
  }

  getPolledData() {
    this.polledService.notifyOthers.subscribe((polledData: any) => {
      this.setAlert(polledData[1].data);
      this.showGreenIndicator = polledData[2].data.hasNewManualChanges;
      this.showOrangeIndicator = polledData[2].data.hasOlderThanTwoHoursManualChanges;

      if(this.showOrangeIndicator){
        this.showGreenIndicator = false;
      }

      this.showRedIndicator = polledData[2].data.hasStatusesWithErrors;
      this.totalCount = polledData[2].data.changesCount;
      this.statusService.statusChanged$.subscribe(() => this.totalCount);

      if(this.totalCount > 0){
        this.totalCountString = '(' + this.totalCount + ')';
      }else{
        this.totalCountString =  '';
      }
    })
  }

  setAlert(alert: IAlert) {
    this.alert = alert;
    this.alertService.alert$.emit(alert);
  }

  logout() {
    this.userService.logout().subscribe(() => {
      this.router.navigateByUrl("/user/login");
      this.auth.clearSessionToken();
    });
  }

  showHideMenu() {
    this.showMenu = !this.showMenu;
  }

  setDesktopMenuItems() {
    this.desktopMenuItems = [
      {
        label: 'Other',
        items: [
          {label: 'Sync log', routerLink: '/sync'},
          {label: 'Accounts', routerLink: '/account'},
          {label: 'Templates', routerLink: '/template'}
        ]
      }
    ]
  }

  // close menu's when scrolling
  @HostListener('window:scroll', ['$event'])
  hideNavbarMenus(){
    this.desktopMenu.hide();
    this.userMenu.hide();
  }

}

export interface IStatusCount {
  normalCount: number;
  csvCount: number;
  totalCount: number;
}
