import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import ErrorMessageBuilder from '../../classes/error-message-builder';
import { IErrorMessage } from '../../interfaces/error-message';

@Component({
  selector: 'mave-validation',
  standalone: true,
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent {
  @Input() control!: AbstractControl | null;
  @Input() name: string = "field";
  @Input() errorMessage!: IErrorMessage;


  constructor() { }

  get message() {
    if (!this.control?.touched) {
      return ""
    }

    return new ErrorMessageBuilder(this.control?.errors, this.name, this.errorMessage).build();
  }

}

// TODO: put this somewhere else and refractor it to an interface class with message builder instances.

