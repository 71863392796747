import { Injectable } from '@angular/core';
import { addHours, format, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public getAuthorizationToken() {
    return localStorage.getItem('token');
  }

  public hasAuthorizationToken() {
    return localStorage.getItem('token') !== null;
  }

  public setAuthorizationToken(token: string) {
    return localStorage.setItem('token', token);
  }

  public setExpirationDate() {
    localStorage.setItem('expiredAt', addHours(new Date(), 100).toISOString());
  }

  public isExpired() {
    let expiredAtRaw = localStorage.getItem('expiredAt');


    if (expiredAtRaw === undefined || expiredAtRaw === null) {
      return true;
    }

    let expirationDate = parseISO(expiredAtRaw);


    return expirationDate < new Date()
  }

  public clearSessionToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("expiredAt");
  }

  public setUserId(id: number) {
    return localStorage.setItem('user', id.toString());
  }

  public getUserId(): number {
    return parseInt(localStorage.getItem('user') ?? '');
  }

}
