import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NavbarItemComponent } from './components/navbar-item/navbar-item.component';
import { RouterModule } from '@angular/router';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { DateselectComponent } from './components/dateselect/dateselect.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { SummaryPipe } from './pipes/summary.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangeselectMultiComponent } from './components/rangeselect-multi/rangeselect-multi.component';
import { RangePipe } from './pipes/range.pipe';
import { SummaryToRangePipe } from './pipes/summary-to-range.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { AdNamePipe } from './pipes/ad-name.pipe';
import { SummariesPipe } from './pipes/summaries.pipe';
import { RefreshMetricsComponent } from './components/refresh-metrics/refresh-metrics.component';
import { WorkbarComponent } from './components/workbar/workbar.component';
import { MicronsPipe } from './pipes/microns.pipe';
import { AccountEditableComponent } from './components/account-editable/account-editable.component';
import { AlertFormComponent } from './forms/alert-form/alert-form.component';
import { CalendarModule } from 'primeng/calendar';
import { RefreshModalComponent } from './components/refresh-modal/refresh-modal.component';
import { VersionComponent } from './components/version/version.component';
import { SplitButtonModule } from 'primeng/splitbutton'
import { SummarizeModalComponent } from './components/summarize-modal/summarize-modal.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { AccountStatusPipe } from './pipes/account-status.pipe';
import { MenuModule } from 'primeng/menu';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { LimitTrailingPipe } from './pipes/limit-trailing.pipe';
import { TitleComponent } from '../mavejs/text/title/title.component';
import { NotifyBadgeComponent } from '../mavejs/base/notify-badge/notify-badge.component';
import { EditableComponent } from '../mavejs/form/editable/editable.component';
import { ActionComponent } from '../mavejs/base/action/action.component';
import { CardComponent } from '../mavejs/base/card/card.component';
import { MenuComponent } from '../mavejs/base/menu/menu.component';
import { LabelComponent } from '../mavejs/text/label/label.component';
import { SubTitleComponent } from '../mavejs/text/sub-title/sub-title.component';
import { ButtonComponent } from '../mavejs/form/button/button.component';
import { LoaderComponent } from '../mavejs/http/loader/loader.component';
import { ModalComponent } from '../mavejs/ui/modal/modal.component';
import { MenuItemComponent } from '../mavejs/base/menu-item/menu-item.component';
import { MenuSplitComponent } from '../mavejs/base/menu-split/menu-split.component';
import { TextboxComponent } from '../mavejs/form/textbox/textbox.component';
import { ValidationComponent } from '../mavejs/form/validation/validation.component';
import { SelectDirective } from '../mavejs/form/select.directive';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DaterangepickerComponent } from './ui/daterangepicker/daterangepicker.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NotFoundComponent,
    NavbarItemComponent,
    UserMenuComponent,
    DateselectComponent,
    StatisticsComponent,
    SummaryPipe,
    RangeselectMultiComponent,
    RangePipe,
    SummaryToRangePipe,
    ShortenPipe,
    AdNamePipe,
    SummariesPipe,
    RefreshMetricsComponent,
    WorkbarComponent,
    MicronsPipe,
    AccountEditableComponent,
    AlertFormComponent,
    RefreshModalComponent,
    VersionComponent,
    SummarizeModalComponent,
    AccountStatusPipe,
    ClickedOutsideDirective,
    LimitTrailingPipe,
    SummarizeModalComponent,
    
  ],
  imports: [
    // Components
    TitleComponent,
    NotifyBadgeComponent,
    EditableComponent,
    ActionComponent,
    CardComponent,
    MenuComponent,
    MenuItemComponent,
    MenuSplitComponent,
    LabelComponent,
    SubTitleComponent,
    ButtonComponent,
    LoaderComponent,
    ModalComponent,
    TextboxComponent,
    ValidationComponent,
    SelectDirective,
    NotificationsComponent,
    DaterangepickerComponent,

    // Modules
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SplitButtonModule,
    CalendarModule,
    MenuModule,
    ConfirmDialogModule,
  ],
  providers: [
    ConfirmationService
  ],
  exports: [
    NavbarComponent,
    NotFoundComponent,
    NavbarItemComponent,
    UserMenuComponent,
    DateselectComponent,
    StatisticsComponent,
    SummaryPipe,
    RangeselectMultiComponent,
    RangePipe,
    SummaryToRangePipe,
    ShortenPipe,
    AdNamePipe,
    WorkbarComponent,
    MicronsPipe,
    PercentPipe,
    AccountEditableComponent,
    AlertFormComponent,
    RefreshModalComponent,
    VersionComponent,
    AccountStatusPipe,
    ClickedOutsideDirective,
    LimitTrailingPipe,
    SummarizeModalComponent,
    DaterangepickerComponent,
  ]
})
export class LibModule { }
