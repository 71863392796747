import { Injectable, EventEmitter } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { IAlert } from '../interfaces/ialert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alert$: EventEmitter<IAlert>;

  constructor(private backend: BackendService) {
    this.alert$ = new EventEmitter();
  }

  clearAlert() {
    return this.backend.sendPost('/api/v1/alert/clear', {});
  }

  createAlert(alert: IAlert) {
    return this.backend.sendPost('/api/v1/alert', alert);
  }

  send(alert: IAlert) {
    this.alert$.emit(alert);
  }

}
