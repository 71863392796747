import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { IAccount } from 'src/app/account/interfaces/account';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(private backend: BackendService) { }

  public getSynchronizationsPaginated(accountId: number, itFailed: boolean, page: number = 0, size: number = 20) {
    let failedToString = itFailed ? 1 : 0;

    if (accountId == 0 || accountId === undefined) {
      return this.backend.getPaginated('/api/v1/sync?failed=' + failedToString, size, page);
    }

    return this.backend.getPaginated('/api/v1/sync?account=' + accountId + "&failed=" + failedToString, size, page);
  }

  public getSynchronizationsFailedToday() {
    return this.backend.getAll("/api/v1/sync/failed/today");
  }

  public syncAllData(date: Date) {
    return this.backend.sendPost("/api/v1/sync/all/" + date.getFullYear() + '/' + (date.getMonth() + 1) + "/" + date.getDate(), {});
  }

  public syncAllDataToday() {
    return this.backend.sendPost("/api/v1/sync/today", {});
  }

  public syncAllDataForAccount(accountId: number) {
    return this.backend.sendPost("/api/v1/sync/" + accountId + "/refresh", {});
  }

  public getLatest(): Observable<any> {
    return this.backend.getSingle("/api/v1/sync/latest");
  }

  public getStatus(): Observable<any> {
    return this.backend.getSingle("/api/v1/sync/status")
  }

}
