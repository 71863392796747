import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'mave-loader',
  imports: [NgIf, NgClass],
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnChanges {
  @Input() label: string = "Loading";
  @Input() isLoading: boolean = true;
  @Input() isSmall: boolean = false;

  loading: boolean = this.isLoading;

  constructor() { }

  ngOnChanges(): void {
    this.loading = this.isLoading;
  }

}
