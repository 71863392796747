import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountStatus'
})
export class AccountStatusPipe implements PipeTransform {

  types = {
    3: 'bg-red',
    2: 'bg-gray',
    1: 'bg-green'
  }

  transform(value: string): string {
    if (value === "Banned") {
      return 'bg-red';
    }

    if (value === "Active") {
      return 'bg-green';
    }
    
    return 'bg-gray'
  }

}
