import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { IComment } from '../classes/comment';
import { ICommentThread } from '../interfaces/icomment-thread';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private backend: BackendService) { }


  public getComments(accountId: number, campaignId: number = 0) {
    if (campaignId != 0) {
      return this.backend.getAll("/api/v1/comment/" + campaignId + "/campaign?tenant=" + accountId);
    }

    return this.backend.getAll("/api/v1/comment/account?tenant=" + accountId);
  }

  public getCommentThreadById(commentType: string, accountId: number, commentThreadId?: number): Observable<ICommentThread> {
    let url: string = '';
    let commentThreadIdFormatted = (commentThreadId ?? 0);

    if(commentType === 'account'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted;
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted + "?tenant=" + accountId;
    }

    return this.backend.getAll(url);
  }

  public getCommentsByThreadId(commentType: string, accountId: number, commentThreadId?: number): Observable<Array<IComment>> {
    let url: string = '';
    let commentThreadIdFormatted = (commentThreadId ?? 0);

    if(commentType === 'account'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted + "/comments";
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted + "/comments?tenant=" + accountId;
    }

    return this.backend.getAll(url);
  }

  public saveComment(commentType: string, accountId: number, text: string, commentThreadId: number, campaignId: number = 0) {
    let url: string = '';

    if(commentType === 'account'){
      url = "/api/v1/comment/save";
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/save?tenant=" + accountId;
    }

    return this.backend.sendPost(url, { commentThreadId: commentThreadId, campaignId: (campaignId == 0 ? null : campaignId), text: text })
  }

  public deleteComment(commentType: string, commentId: number, accountId: number) {
    let url: string = '';

    if(commentType === 'account'){
      url = "/api/v1/comment/" + commentId + "/delete";
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/" + commentId + "/delete?tenant=" + accountId;
    }

    return this.backend.sendDelete(url);
  }

  public flagAsRead(commentType: string, accountId: number, commentThreadId: number) {
    let commentThreadIdFormatted = (commentThreadId ?? 0);
    let url: string = '';

    if(commentType === 'account'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted  + "/read";
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/thread/" + commentThreadIdFormatted  + "/read?tenant=" + accountId;
    }

    return this.backend.getSingle(url);
  }

  public deleteCommentThread(commentType: string, accountId: number, commentThreadId: number) {
    let url: string = '';

    if(commentType === 'account'){
      url = "/api/v1/comment/thread/" + commentThreadId + "/delete";
    }else if (commentType === 'campaign'){
      url = "/api/v1/comment/thread/" + commentThreadId + "/delete?tenant=" + accountId;
    }

    return this.backend.sendDelete(url);
  }

}
