import { HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry, delay, retryWhen } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { PolledService } from '../services/polled.service';

export const serverErrorInterceptor: HttpInterceptorFn = (req, next) => {

  let router = inject(Router);
  let auth = inject(AuthService);
  let polling = inject(PolledService);

  return next(req).pipe(
    retry({count: 2, delay: (error) => {
      if (error.status === HttpStatusCode.NotFound) {
        return throwError(() => error);
      }

      return next(req);
    }}),
    catchError((error: any) => {
      if (error.status === HttpStatusCode.InternalServerError || error.status === HttpStatusCode.BadRequest) {
        router.navigateByUrl('/errors/500');
      }

      return throwError(() => new Error(error));
    })
  )
};
