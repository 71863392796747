<div class="flex flex-col justify-between lg:flex-row lg:justify-around lg:items-center" *ngIf="statistics">
  <div class="flex w-100 py-2">
    <div class="flex justify-center items-center stat-bg-green h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined stat-green h-6">point_of_sale</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold stat-green">{{ statistics.conversionsTracked }}</div>
      <div class="font-bold stat-green-light">Conversions</div>
    </div>
  </div>
  <!-- <div class="flex w-100 py-2">
    <div class="flex justify-center items-center stat-bg-blue h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined stat-blue h-6">ads_click</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold stat-blue">{{ statistics.clicks }}</div>
      <div class="font-bold stat-blue-light">Clicks</div>
    </div>
  </div> -->
  <div class="flex w-100 py-2">
    <div class="flex justify-center items-center stat-bg-red h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined stat-red h-6">attach_money</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold stat-red" (mouseover)="showHideOverlay()" (mouseleave)="showHideOverlay()">{{ statistics.cost }}</div>
      <div class="font-bold stat-red-light">Cost</div>

      <div *ngIf="showOverlay && showCostOverlay" class="border-2 border-red-dark rounded-xl p-5 absolute bg-white shadow-xl -mt-5 ml-5">
        <div class="flex flex-col text-right gap-1">
          <span class="text-xl font-bold stat-red">{{statistics.costEur}}</span>
          <span class="text-xl font-bold stat-red">{{statistics.costUsd}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="flex w-100 py-2">
    <div class="flex justify-center items-center stat-bg-yellow h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined stat-yellow h-6">price_check</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold stat-yellow">{{ statistics.costConv }}</div>
      <div class="font-bold stat-yellow-light">Cost/Conversion</div>
    </div>
  </div> -->
  <div class="flex w-100 py-2">
    <div class="flex justify-center items-center bg-gray h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined text-black h-6">build_circle</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold text-black-lighter">{{ statistics.reliability }}</div>
      <div class="font-bold text-gray">Reliability</div>
    </div>
  </div>
  <div class="flex w-100 py-2">
    <div class="flex justify-center items-center bg-gray h-12 w-12 rounded-full mr-4 mt-1 xxl:h-16 xxl:w-16">
      <i class="material-symbols-outlined text-black h-6">trending_up</i>
    </div>
    <div>
      <div class="text-2xl xxl:text-4xl font-bold text-black-lighter">{{ statistics.roi }} <span class="text-gray" *ngIf="showRealRoi">/ {{statistics.realROI}}</span></div>
      <div class="font-bold text-gray">ROI</div>
    </div>
  </div>
</div>
