export interface GraphPainter {
  setValue(value: number): void;
  getColor(): string;
}

export class CampaignGraphPainter implements GraphPainter {
  private value: number = 0;

  public setValue(value: any) {
    this.value = value.roi;
  }

  /**
   *
   * @param hex
   * @returns
   */
  public pad(hex: string) {
    if (hex.length === 1) {
      return "0" + hex;
    }

    if (hex.length > 2 || hex.length === 0) {
      return "00";
    }

    return hex;
  }

  public getColor(): string {
    let percentage = (this.value);

    let blue = 0;
    let green = Math.round((percentage + 30) * 5.1);
    let red = Math.round(255 - Math.abs(((Math.abs(percentage + 5) - 25) * 5.1)));

    if (red > 255) {
      red = 255;
    }

    if (green > 255) {
      green = 255;
    }

    if (red < 0) {
      red = 0;
    }

    if (green < 0) {
      green = 0;
    }

    return '#' + this.pad(red.toString(16)) + this.pad(green.toString(16)) + this.pad(blue.toString(16));
  }
}


export class FillGraphPainter implements GraphPainter {

  value: any;

  setValue(value: any): void {
    this.value = value.percentage;
  }

  getColor(): string {
    if (this.value <= 30) {
      return "red";
    } else if (this.value >= 31 && this.value <= 80) {
      return "orange";
    } else if (this.value >= 81 && this.value <= 110) {
      return "green";
    } else if (this.value >= 110) {
      return "yellow";
    }

    return "grey";
  }

}


export class AccountGraphPainter implements GraphPainter {
  private value: number = 0;

  public setValue(value: number) {
    this.value = value;
  }

  public getColor(): string {
    if (this.value < 0) {
      return "#FF4B4B";
    } else if (this.value >= 0 && this.value <= 20) {
      return "#ffdd00";
    } else if (this.value > 20) {
      return "#71EE52";
    }

    return "grey";
  }
}

export class FixedColorGraphPainter implements GraphPainter {

  color!: string;


  constructor(color: string) {
    this.color = color;
  }

  setValue(value: number): void {

  }

  getColor(): string {
    return this.color;
  }

}
