<mave-notification-area [keepAlive]="3000"></mave-notification-area>

<div class="ga-navbar-fixed-wrapper">
  <div class="ga-alert ga-alert-red" [class.ga-alert-activated]="hasAlert"><span class="material-symbols-outlined mr-3">warning</span> {{ alert.message }}</div>
  <div class="ga-alert" [class.ga-alert-activated]="isTesting">Testing environment</div>
  <ganal-navbar [pageTitle]="pageTitle" *ngIf="!hideNavBar"></ganal-navbar>
</div>

<div
[class.ga-padding-navbar-only]="!hideNavBar && !hasAlert && !isTesting"
[class.ga-padding-navbar-and-one-alert]="!hideNavBar && (hasAlert || isTesting)"
[class.ga-padding-navbar-and-two-alerts]="!hideNavBar && (hasAlert && isTesting)"
class="w-full p-2 lg:p-10">
    <router-outlet (activate)="getPageTitle()"></router-outlet>
</div>
<ganal-version *ngIf="!hideNavBar"></ganal-version>

<p-confirmDialog #adBlocker header="Adblocker detected" icon="pi pi-exclamation-triangle" key="adBlocker" [rejectVisible]="false" [closable]="false">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Done" (click)="adBlocker.accept()"></button>
  </ng-template>
</p-confirmDialog>
