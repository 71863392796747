<div class="mv-card" [ngStyle]="{backgroundColor: bgColor}">
    <div class="p-4 rounded rounded-b-none rounded-lg" [ngClass]="headerClasses" *ngIf="header">
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="mv-card-content" [ngClass]="{'p-5': border}">
        <ng-content></ng-content>
    </div>
    <div class="p-5 rounded rounded-t-none rounded-lg" *ngIf="footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>

