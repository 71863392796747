<div class="ga-notification-button" (click)="read($event)" *ngIf="!isLoading">
  <div class="ga-notification-unread" *ngIf="hasUnread">
    <div class="material-symbols-outlined">priority_high</div>
  </div>
  <div class="ga-notification-icon material-symbols-outlined">notifications</div>
</div>

<p-overlayPanel #notificationPanel>

  <ng-template pTemplate>
    <div class="ga-notification-holder">
      <div class="ga-notification-card" [routerLink]="getLink(notification)" *ngFor="let notification of feed.notifications">
        <div class="ga-notification-card-title">{{ notification.title }}</div>
        <div class="ga-notification-card-message">{{ notification.message }}</div>
      </div>
    </div>
  </ng-template>

</p-overlayPanel>

