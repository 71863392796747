<div class="text-xs relative z-30">
  <div class="absolute w-24 lg:w-28 bottom-6 -ml-1">
    <p-badge [value]="commentCount" styleClass="mr-1 bg-primary" *ngIf="isRead && commentCount !== '0'"></p-badge>
    <p-badge [value]="commentCount" severity="danger" styleClass="mr-1" *ngIf="!isRead && commentCount !== '0'"></p-badge>
    <p-badge value="New" severity="danger" severity="danger" *ngIf="!isRead && commentCount !== '0'" class="ml-0.5"></p-badge>
  </div>

  <mave-loader [isSmall]="true" [isLoading]="isLoadingCommentsOpen">
    <mave-action icon="mode_comment" (click)="openComments()"></mave-action>
  </mave-loader>

</div>

<ganal-comment-modal #commentModal
                     [commentThread]="commentThread"
                     [request]="request"
                     [commentType]="commentType"
                     (modalClosed)="refreshBadges()">
</ganal-comment-modal>
