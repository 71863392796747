import {
    addDays,
    differenceInDays,
    endOfMonth, endOfWeek,
    endOfYear,
    startOfMonth,
    startOfToday,
    startOfWeek,
    startOfYear,
    startOfYesterday, subDays,
    subMonths,
    subWeeks
} from "date-fns";
import { format } from 'date-fns'

export class DateRange {
    from = new Date();
    to = new Date();

    constructor(from: Date, to: Date) {
        this.from = from;
        this.to = to;
    }

    getFrom(): Date {
        return this.from;
    }

    getTo(): Date {
        return this.to;
    }

    setFrom(date: Date) {
        this.from = date;
    }

    setTo(date: Date) {
        this.to = date;
    }

    getFromFormatted(): string {
        return format(this.from, 'yyyy-MM-dd');
    }

    getToFormatted(): string {
        return format(this.to, 'yyyy-MM-dd');
    }

    getFromFormattedView(): string {
        return format(this.from, 'yyyy/MM/dd');
    }

    getToFormattedView(): string {
        return format(this.to, 'yyyy/MM/dd');
    }

    getDiff(): number {
        let diff = differenceInDays(this.to, this.from)
        return diff === 0 ? diff + 1 : diff;
    }

    isValid(): boolean {
        if (this.from === undefined || this.to === undefined) {
            return false;
        }

        if (this.from === null || this.to === null) {
            return false;
        }

        return true;
    }

    toString(): string {
        return this.getFromFormatted() + " - " + this.getToFormatted();
    }
}

export interface RangeMapping {
    [key: string]: DateRange | undefined
}

export interface RangeReverseMapping {
    [range: string]: string
}

export class Ranges {
    
    static getDefault(): DateRange {
        return new DateRange(subWeeks(startOfToday(), 1), startOfYesterday())
    }

    static getRanges(): RangeMapping {
        return {
            'TODAY': new DateRange(startOfToday(), startOfToday()),
            'YESTERDAY': new DateRange(startOfYesterday(), startOfYesterday()),
            'LAST_7_DAYS': new DateRange(subWeeks(startOfToday(), 1), startOfYesterday()),
            'THIS_WEEK': new DateRange(addDays(startOfWeek(new Date()), 1), new Date()),
            'LAST_WEEK': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 1)), 1), addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
            'THIS_MONTH': new DateRange(startOfMonth(new Date()), endOfMonth(new Date())),
            'LAST_MONTH': new DateRange(startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))),
            'LAST_14_DAYS': new DateRange(subWeeks(startOfToday(), 2), startOfYesterday()),
            'LAST_30_DAYS': new DateRange(subDays(startOfToday(), 30), startOfYesterday()),
            'ONE_WEEK_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 1)), 1), addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
            'TWO_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 2)), 1), addDays(endOfWeek(subWeeks(new Date(), 2)), 1)),
            'THREE_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 3)), 1), addDays(endOfWeek(subWeeks(new Date(), 3)), 1)),
            'FOUR_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 4)), 1), addDays(endOfWeek(subWeeks(new Date(), 4)), 1)),
            'THIS_YEAR': new DateRange(startOfYear(new Date()), endOfYear(new Date())),
            'CUSTOM': undefined
        }
    }

    static getRangesReversed(): RangeReverseMapping {
        const ranges = Ranges.getRanges();

        const today = ranges['TODAY']?.toString() ?? '';
        const yesterday = ranges['YESTERDAY']?.toString() ?? '';
        const lastSevenDays = ranges['LAST_7_DAYS']?.toString() ?? '';
        const thisWeek = ranges['THIS_WEEK']?.toString() ?? '';
        const lastWeek = ranges['LAST_WEEK']?.toString() ?? '';
        const thisMonth = ranges['THIS_MONTH']?.toString() ?? '';
        const lastMonth = ranges['LAST_MONTH']?.toString() ?? '';
        const lastFourtheenDays = ranges['LAST_14_DAYS']?.toString() ?? '';
        const lastThirtyDays = ranges['LAST_30_DAYS']?.toString() ?? '';
        const oneWeekAgo = ranges['ONE_WEEK_AGO']?.toString() ?? '';
        const twoWeeksAgo = ranges['TWO_WEEKS_AGO']?.toString() ?? '';
        const threeWeeksAgo = ranges['THREE_WEEKS_AGO']?.toString() ?? '';
        const fourWeeksAgo = ranges['FOUR_WEEKS_AGO']?.toString() ?? '';

        return {
            [today]: 'TODAY',
            [yesterday]: 'YESTERDAY',
            [thisWeek]: 'THIS_WEEK',
            [lastWeek]: 'LAST_WEEK',
            [thisMonth]: 'THIS_MONTH',
            [lastMonth]: 'LAST_MONTH',
            [lastFourtheenDays]: 'LAST_14_DAYS',
            [lastThirtyDays]: 'LAST_30_DAYS',
            [oneWeekAgo]: 'ONE_WEEK_AGO',
            [twoWeeksAgo]: 'TWO_WEEKS_AGO',
            [threeWeeksAgo]: 'THREE_WEEKS_AGO',
            [fourWeeksAgo]: 'FOUR_WEEKS_AGO',
            [lastSevenDays]: 'LAST_7_DAYS'
        }
    }

    static isCustom(range: DateRange) {
        return this.getRangesReversed()[range.toString()] === undefined
    }
}

