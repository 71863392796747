import {Component, HostListener, NgZone, OnChanges, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { IAlert } from './lib/interfaces/ialert';
import { AlertService } from './lib/services/alert.service';
import {PolledService} from "./lib/services/polled.service";
import {ConfirmationService} from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AuthService } from './lib/services/auth.service';

// import * as SockJs from 'sockjs-client';

@Component({
  selector: 'ganal-root',
  templateUrl: './ganal.component.html',
  styleUrls: ['./ganal.component.scss']
})
export class GanalComponent implements OnInit {
  title: string = "Mave Google Analytics"
  alert: IAlert = { message: "" };
  pageTitle?: string;
  isTesting!: boolean;

  constructor(private router: Router, private auth: AuthService, private alertService: AlertService, private prime: PrimeNGConfig, private ngZone: NgZone, private polledService: PolledService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.prime.ripple = true;
    this.isTesting = !environment.production;

    this.checkIfAdBlockerIsOn();

    this.alertService.alert$.subscribe((alert: IAlert) => {
      this.alert = alert;
    })

    if (this.auth.hasAuthorizationToken()) {
      this.polledService.initializePolling();
    }
    
    // Zorgt ervoor dat bij een keyup niet alle achterliggende acties uitgevoerd worden. Denk aan de input lagg...
    // https://stackoverflow.com/questions/49805782/how-to-prevent-or-cancel-change-detection-on-global-keydown-events
    this.ngZone.runOutsideAngular(() => {
      window.document.addEventListener("keyup", (event: KeyboardEvent) => {
        event.stopPropagation();
      });
    });

    this.setHolidayTheme();
  }

  ngAfterViewInit() {

  }

  get hasAlert() {
    return (this.alert.message != "")
  }

  get hideNavBar() {
    return this.router.url.includes('/user/login')
  }

  getPageTitle(){
    if(this.router.url === '/'){
      this.pageTitle = '';
    }else if (this.router.url === '/status'){
      this.pageTitle = 'Statusses'
    }else if (this.router.url === '/status/status-changes'){
      this.pageTitle = 'Status changes'
    }else if (this.router.url === '/template'){
      this.pageTitle = 'Templates'
    }else if (this.router.url === '/account'){
      this.pageTitle = 'Accounts'
    }else if (this.router.url === '/sync'){
      this.pageTitle = 'Synchronization Log'
    }else if (this.router.url === '/account/permissions') {
      this.pageTitle = 'User Management'
    }else if (this.router.url === '/user/profile'){
      this.pageTitle = 'User Profile'
    }else if (this.router.url.includes('/campaign/account')){
      this.pageTitle = 'Campaign Overview'
    }else if (this.router.url.includes('/ad')){
      this.pageTitle = 'Campaign Details'
    }else if (this.router.url.includes('/campaign/recent')){
      this.pageTitle = 'Recent Campaigns'
    }else if (this.router.url.includes('/daily-reports')){
      this.pageTitle = 'Daily Reports'
    }else if (this.router.url === '/reports'){
      this.pageTitle = 'Reports'
    }else{
      this.pageTitle = '';
    }
  }

  async checkIfAdBlockerIsOn() {
    await fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    }).then((response) => {
      if(response.redirected){
        this.confirmationService.confirm({
          key: 'adBlocker',
          message: 'Disable adblocker before you can continue working!',
          accept: () => {
            this.checkIfAdBlockerIsOn().then(r => {
              window.location.reload();
            });
          }
        });
      }
    });
  }

  setHolidayTheme(){
    var date = new Date();
    const ganalroot = Array.from(document.getElementsByTagName('ganal-root') as HTMLCollectionOf<HTMLElement>);

    // VALENTIJN
    if(date.getDate() == 14 && date.getMonth() + 1 == 2) {
      document.body.style.backgroundImage = 'url("https://media.istockphoto.com/id/1179161006/vector/cute-childish-seamless-pattern-background-with-funny-kawaii-cartoon-characters-of-hearts.jpg?s=612x612&w=0&k=20&c=CdvaJ4c50HWYBQX_wpmKth6Od_xSrxa6tL0cHclmLZ8=")';
    }

    // PASEN - 2024
    if((date.getDate() >= 29 && date.getMonth() + 1 == 3) || (date.getDate() <= 2 && date.getMonth() + 1 == 4)) {
      document.body.style.backgroundImage = 'url("https://img.freepik.com/premium-vector/pasen-lentepatroon-met-leuke-eieren-en-bloemen-hand-getekend-platte-cartoon-elementen_113065-965.jpg?w=2000")';
    }

    // HEMELVAART - 2023
    if((date.getDate() == 18 || date.getDate() == 19 )&& date.getMonth() + 1 == 5) {
      document.body.style.backgroundImage = 'url("https://e0.pxfuel.com/wallpapers/423/573/desktop-wallpaper-mother-mary-virgin-mary.jpg")';
    }

    // HALLOWEEN
    if((date.getDate() == 30 || date.getDate() == 31 )&& date.getMonth() + 1 == 10) {
      document.body.style.backgroundImage = 'url("https://w0.peakpx.com/wallpaper/423/496/HD-wallpaper-halloween-holiday-background-bats-ultra-holidays-halloween-background-bats-holiday-jackolanterns.jpg")';
    }

    // Sinterklaas
    if((date.getDate() >= 5 && date.getDate() <= 6) && date.getMonth() + 1 == 12) {
      document.body.style.backgroundImage = 'url("https://www.shutterstock.com/shutterstock/photos/2209171155/display_1500/stock-vector-sinterklaas-seamless-pattern-design-dutch-saint-nicholas-vector-illustrations-2209171155.jpg")';
    }

    // KERST
    if((date.getDate() >= 21 && date.getDate() <= 27) && date.getMonth() + 1 == 12) {
      document.body.style.backgroundImage = 'url("https://static.vecteezy.com/system/resources/previews/004/930/887/original/candy-cane-with-snowflakes-christmas-background-seamless-pattern-for-printable-illustration-wallpaper-decoration-vector.jpg")';
    }
  }

}
