<ganal-summarize-modal #summarizeModal></ganal-summarize-modal>
<p-confirmDialog #cd header="Do you want to resync today?" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" class="p-button-secondary" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
<div class="ml-2">
  <mave-loader [isLoading]="!latestSynchronization" [isSmall]="true">
    <!--  DESKTOP  -->
    <div class="hidden lg:flex lg:items-center">
      <!-- <p-splitButton class="" [model]="items" label="Sync Today" [icon]="isSyncing ? 'pi pi-spinner pi-spin' : 'pi pi-refresh'" [disabled]="isSyncing" (onClick)="syncAllData()"></p-splitButton> -->
      <div *ngIf="latestSynchronization" class="md:block bg-secondary-lightest rounded rounded-lg py-1 px-4 font-bold text-xs text-primary">{{ latestSynchronization | date: 'MMM d, y - HH:mm:ss': '+0100' }}</div>
    </div>

    <!--  MOBILE  -->
    <div class="mt-3 lg:hidden">
      <div class="flex flex-col items-center">
        <div *ngIf="latestSynchronization" class="inline-block py-1 px-4 font-bold text-sm text-secondary-lighter">{{ latestSynchronization | date: 'MMM d, y' }}</div>
        <div *ngIf="latestSynchronization" class="inline-block py-1 px-4 font-bold text-sm text-secondary-lighter">{{ latestSynchronization | date: 'HH:mm:ss': '+0100' }}</div>
      </div>
    </div>
  </mave-loader>
</div>
