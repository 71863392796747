import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { CardComponent } from '../../base/card/card.component';
import { SubTitleComponent } from '../../text/sub-title/sub-title.component';
import { ActionComponent } from '../../base/action/action.component';
import { NgIf } from '@angular/common';


@Component({
  selector: 'mave-modal',
  imports: [CardComponent, SubTitleComponent, ActionComponent, NgIf],
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
// @Deprecated
export class ModalComponent implements OnInit {
  @Input() title: string = 'Modal Title'
  @Input() open: boolean = false;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  isOpen = false;

  constructor() { }

  ngOnInit(): void {
    this.isOpen = this.open;
  }

  openModal() {
    this.isOpen = true;

    /* Makes sure the background doesn't scroll when modal is open */
    const body = document.querySelector("body");
    if(body){
      body.style.overflow = "hidden";
    }
  }

  closeModal() {
    this.isOpen = false;
    this.modalClosed.emit();

    /* Makes background scrollable again */
    const body = document.querySelector("body");
    if(body){
      body.style.overflow = "auto";
    }
  }

  @HostListener("window:keyup", ['$event'])
  closeOnEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }

}
