import { Injectable } from '@angular/core';
import { DataResponse } from '../../mavejs/http/interfaces/data-response';
import { Observable } from 'rxjs';
import { IAccount } from 'src/app/account/interfaces/account';
import { User } from 'src/app/account/views/permissions/permissions.component';
import { AuthService } from 'src/app/lib/services/auth.service';
import { BackendService } from 'src/app/lib/backend.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private backend: BackendService, private auth: AuthService) {
  }

  public login(username: string, password: string): Observable<any> {
    return this.backend.sendPost("/authenticate", { email: username, password: password });
  }

  public logout() {
    return this.backend.sendPost("/destroy_token", { token: this.auth.getAuthorizationToken() })
  }

  public getUsers() {
    return this.backend.getAll("/api/v1/user");
  }

  public getPermittedAccounts(user: User): Observable<Array<IAccount>> {
    return this.backend.getAll("/api/v1/user/" + user.id + "/permitted_accounts");
  }

  public setPermittedAccounts(user: User, accounts: Array<IAccount>) {
    return this.backend.sendPost("/api/v1/user/" + user.id + "/permitted_accounts", accounts);
  }

  public toggleAdmin(user: User) {
    return this.backend.sendPost("/api/v1/user/" + user.id + "/admin/toggle", {});
  }

  public toggleReports(user: User) {
    return this.backend.sendPost("/api/v1/user/" + user.id + "/reports/toggle", {});
  }

  public getSelf(): Observable<User> {
    return this.backend.getSingle("/api/v1/user/self");
  }

  public toggleEmailNotifications(user: User): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/user/optin/toggle/", {});
  }

  public updateUsername(userId: number, newUsername: string){
    return this.backend.sendPut("/api/v1/user/" + userId + "/save_name", newUsername)
  }

  public saveUserProfile(user: User): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/user/" + user.id + "/profile", user);
  }

}
