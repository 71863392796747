import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PaginatedResponse } from '../mavejs/http/interfaces/paginated-response';

@Injectable({
  providedIn: 'root'
})
export class BackendService {


  /**
   *
   */
  private headers: HttpHeaders;

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
  }

  /**
   *
   * @param url
   * @returns
   */
  public getAll(url: string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + url, { headers: this.headers });
  }

  /**
   *
   * @param url
   * @returns
   */
  public getSingle(url: string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + url, { headers: this.headers });
  }

  /**
   *
   * @param url
   * @param size
   * @param page
   * @returns
   */
  public getPaginated(url: string, size: number = 20, page: number = 0): Observable<PaginatedResponse> {
    return this.http.get<PaginatedResponse>(environment.baseUrl + url, { params: { size: size, page: page }, headers: this.headers })
  }

  /**
   *
   * @param url
   * @param body
   * @returns
   */
  public sendPost(url: string, body: any): Observable<any> {
    return this.http.post<any>(environment.baseUrl + url, body, { headers: this.headers });
  }

  /**
   *
   * @param url
   * @param body
   * @returns
   */
  public sendPut(url: string, body: any): Observable<any> {
    return this.http.put<any>(environment.baseUrl + url, body, { headers: this.headers });
  }

  /**
   *
   * @param url
   * @param id
   * @returns
   */
  public sendDelete(url: string): Observable<any> {
    return this.http.delete<any>(environment.baseUrl + url, { headers: this.headers })
  }

  public getRaw(url: string): Observable<string> {
    return this.http.get(environment.baseUrl + url, { responseType: 'text' });
  }
}
