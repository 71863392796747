<div>
  <div class="lg:flex lg:flex-row lg:justify-between">
    <div class="w-full">
      <mave-card [header]="false" [footer]="false">
        <div class="flex items-center sm:flex-col lg:flex-row">
          <div class="sm:hidden lg:flex w-3/12 justify-end -my-6 mr-0 -ml-6 -mb-3" *ngIf="showGraph">
            <mave-loader [isLoading]="isLoadingGraph">
              <p-chart *ngIf="!isLoadingGraph" type="line" [data]="graphData" [options]="graphOptions" [width]="'600px'" [height]="'150px'"></p-chart>
            </mave-loader>
          </div>

          <div class="lg:hidden sm:block" *ngIf="showGraph">
            <mave-loader [isLoading]="isLoadingGraph">
              <p-chart *ngIf="!isLoadingGraph" type="line" [data]="graphData" [options]="graphOptions" [width]="'300px'" [height]="'150px'"></p-chart>
            </mave-loader>
          </div>

          <div [ngClass]="{'w-9/12': showGraph }" [class.w-full]="!showGraph">
            <mave-loader [isLoading]="isLoadingStatistics" [isSmall]="true">

              <ganal-statistics [statistics]="statistics" [showRealRoi]="true" [showCostOverlay]="true"></ganal-statistics>
            </mave-loader>
          </div>
        </div>
      </mave-card>
    </div>
  </div>

  <!--  MOBILE  -->
  <div class="w-full lg:hidden mt-4">
    
  </div>
  <!--    -->
  <ganal-refresh-modal #refreshModal></ganal-refresh-modal>

  <div class="h-4"></div>

  <mave-card [border]="false">
    <div header>
      <div class="flex flex-col items-center lg:grid grid-cols-5 xl:grid-cols-7 xxl:grid-cols-9 xxxl:grid-cols-12">
        <mave-sub-title text="Account Overview" class="col-start-1 col-span-1"></mave-sub-title>
        <!-- <ganal-dateselect (dateChange)="dateRangeChanged($event)" class="col-start-2 col-span-3 hidden lg:block"></ganal-dateselect> -->

        <mv-daterangepicker [date]="range" (dates)="dateRangeChanged($event)"></mv-daterangepicker>

        <div class="col-start-12 col-span-1 text-right">
          <mave-button routerLink="/account" label="Account Details"></mave-button>
        </div>
      </div>
    </div>

    <mave-loader [isLoading]="isLoadingAccounts">
      <!--  DESKTOP  -->
      <mave-datatable (headerSelect)="sort($event)" [empty]="accountMetrics && accountMetrics.length === 0" [headers]="accountHeaders" class="hidden lg:block lg:overflow-x-auto">
        <tr *ngFor="let account of accountMetrics" maveDataTableRow>
          <td mave-datatable-column class="w-5">
            <div class="flex items-center" *ngIf="account">
              <div class="mr-1">
                <mave-tooltip [text]="account.accountStatus">
                  <div [class]="['block', 'h-4', 'w-4', 'rounded', 'rounded-full', account.accountStatus | accountStatus]"></div>
                </mave-tooltip>
              </div>
              <div>
                <mave-tooltip *ngIf="!account.accountCorrectScriptVersion && account.accountName !== 'Unknown Conversions'" text="Wrong script version, click for new version">
                  <a [href]="scriptUrl" target="_blank">
                    <i class="block material-symbols-outlined text-red">error</i>
                  </a>
                </mave-tooltip>
              </div>
            </div>
          </td>
          <td mave-datatable-column>
            <div class="flex justify-between items-center">
              <div class="flex">
                <div class="w-80">
                  <ganal-account-editable [accountId]="account.accountId" [accountName]="account.accountName" *ngIf="account.accountName !== 'Unknown Conversions'">
                    <a [routerLink]="'/campaign/account/' + account.accountId" class="text-secondary whitespace-nowrap">{{ account.accountName }}</a>
                  </ganal-account-editable>
                  <span *ngIf="account.accountName === 'Unknown Conversions'">{{ account.accountName }}</span>
                </div>
                <a [routerLink]="'/campaign/account/' + account.accountId" *ngIf="account.accountName !== 'Unknown Conversions'">
                  <span class="text-primary-light" [ngClass]="{'text-gray': (account && account.accountStatus === 'Inactive')}" *ngIf="account.accountActiveProductName !== null">{{ account.accountActiveProductName }}</span>
                  <span class="bg-gray-light px-2 text-xs rounded-full" *ngIf="account.accountActiveProductName === null">No Product</span>
                </a>
              </div>
              <div class="-m-3 w-10" *ngIf="account">
                <div class="flex items-center">
                  <div>
                    <ganal-comment
                      [commentThread]="account.accountCommentThread"
                      [request]="{tenantId: account.accountId, modelId: 0}"
                      [commentType]="'account'">
                    </ganal-comment>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td mave-datatable-column-mono>{{ account.costFormatted }}</td>
          <td mave-datatable-column-mono>{{ account.profitFormatted }}</td>
          <td mave-datatable-column-mono *ngIf="account"><span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.roi < 0) && account.accountStatus !== 'Banned')}">{{ account.roi === undefined || account.roi === 0 ? '-%' : (account.roi | percent) }}</span></td>
          <td mave-datatable-column-mono><span [ngClass]="highlightConversion(account.conversionsTracked, account.conversions, 'mvolger')">{{ account.conversionsTracked }}</span> / <span class="text-gray" [ngClass]="highlightConversion(account.conversionsTracked, account.conversions, 'google')">{{ account.conversions }}</span></td>
          <td mave-datatable-column-mono>{{ account.revenueFormatted }}</td>
          <td mave-datatable-column-mono>
            <span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.usCpm >= 20) && account.accountStatus !== 'Banned'), 'ga-highlight-orange': ((account.usCpm > 16 && account.usCpm < 20) && account.accountStatus !== 'Banned')}">{{ (account.usCpm | currency: account.accountCurrencyCode:undefined:'1.0') ?? '-%' }}</span>
          </td>
          <td mave-datatable-column-mono *ngIf="(account.accountLastStatusChange | date:'yyyy') === currentYear">{{ account.accountLastStatusChange | date:'MMM d' }}</td>
          <td mave-datatable-column-mono *ngIf="(account.accountLastStatusChange | date:'yyyy') !== currentYear">{{ account.accountLastStatusChange | date:'MMM d, yyyy' }}</td>
          <td class="w-10" mave-datatable-column-mono>
            <mave-tooltip text="Refresh statusses and changes" direction="left">
              <mave-action icon="refresh" (click)="openRefreshAccountModal(account.accountId, account.accountName)"></mave-action>
            </mave-tooltip>
          </td>
        </tr>
      </mave-datatable>
      <!--    -->
      <!--  MOBILE  -->
      <mave-datatable (headerSelect)="sort($event)" [empty]="accountMetrics && accountMetrics.length === 0" [headers]="accountHeadersMobile" class="block overflow-x-auto lg:hidden">
        <tr *ngFor="let account of accountMetrics" maveDataTableRow>
          <td mave-datatable-column class="w-5">
            <div class="flex items-center" *ngIf="account">
              <div class="mr-0">
                <mave-tooltip [text]="account.accountStatus">
                  <div [class]="['block', 'h-4', 'w-4', 'rounded', 'rounded-full', account.accountStatus | accountStatus]"></div>
                </mave-tooltip>
              </div>
              <div>
                <mave-tooltip *ngIf="!account.accountCorrectScriptVersion" text="Wrong script version, click for new version">
                  <a [href]="scriptUrl" target="_blank">
                    <i class="block material-symbols-outlined text-red">error</i>
                  </a>
                </mave-tooltip>
              </div>
            </div>
          </td>
          <td mave-datatable-column>
            <div class="flex justify-between items-center">
              <div class="flex flex-col p-1">
                <div class="w-48">
                  <ganal-account-editable [accountId]="account.accountId" [accountName]="account.accountName">
                    <a [routerLink]="'/campaign/account/' + account.accountId" class="text-secondary whitespace-nowrap">{{ account.accountName }}</a>
                  </ganal-account-editable>
                </div>
                <a [routerLink]="'/campaign/account/' + account.accountId">
                  <span class="text-primary-light" [ngClass]="{'text-gray': (account && account.accountStatus === 'Inactive')}" *ngIf="account.accountActiveProductName !== null">{{ account.accountActiveProductName }}</span>
                  <span class="bg-gray-light px-2 text-xs rounded-full" *ngIf="account.accountActiveProductName === null">No Product</span>
                </a>
              </div>
              <div class="-m-3 w-10" *ngIf="account">
                <div class="flex items-center">
                  <div>
                    <ganal-comment
                      [commentThread]="account.accountCommentThread"
                      [request]="{tenantId: account.accountId, modelId: 0}"
                      [commentType]="'account'">
                    </ganal-comment>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td mave-datatable-column-mono>{{ account.costFormatted }}</td>
          <td mave-datatable-column-mono>{{ account.profitFormatted }}</td>
          <td mave-datatable-column-mono *ngIf="account"><span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.roi < 0) && account.accountStatus !== 'Banned')}">{{ account.roi === undefined || account.roi === 0 ? '-%' : (account.roi | percent) }}</span></td>
          <td mave-datatable-column-mono><span [ngClass]="highlightConversion(account.conversionsTracked, account.conversions, 'mvolger')">{{ account.conversionsTracked }}</span> / <span class="text-gray" [ngClass]="highlightConversion(account.conversionsTracked, account.conversions, 'google')">{{ account.conversions }}</span></td>
          <td mave-datatable-column-mono>{{ account.revenueFormatted }}</td>
          <td mave-datatable-column-mono>
            <span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.usCpm >= 20) && account.accountStatus !== 'Banned'), 'ga-highlight-orange': ((account.usCpm > 16 && account.usCpm < 20) && account.accountStatus !== 'Banned')}">{{ (account.usCpm | currency: account.accountCurrencyCode:undefined:'1.0') ?? '-%' }}</span>
          </td>
        </tr>
      </mave-datatable>
      <!--    -->
    </mave-loader>

    <div footer>
      <span class="font-bold text-secondary text-xs"></span>
    </div>

  </mave-card>

</div>
