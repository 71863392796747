<mave-modal #refreshModal title="Do you want to refresh?">
  <p class="font-bold text-black">This action will refresh all listed data account: {{ accountName }}
  </p>
  <ul class="list-disc ml-12 mt-4 text-black">
    <li>Campaign Statuses</li>
    <li>Ad Statuses</li>
    <li>Demographic Statuses</li>
    <li>Campaigns and campaign changes</li>
    <li>Ads and ad changes</li>
  </ul>
  <div footer>
    <div class="flex justify-between">
      <mave-button label="Refresh" icon="refresh" [loading]="refreshAccountIsLoading" (click)="refreshAccount()"></mave-button>
      <mave-button label="Cancel" variant="secondary" (click)="refreshModal.closeModal()"></mave-button>
    </div>

  </div>
</mave-modal>
