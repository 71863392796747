import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from './components/comment/comment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { CommentModalComponent } from './components/comment-modal/comment-modal.component';
import { CommentOverviewComponent } from './components/comment-overview/comment-overview.component';
import { ActionComponent } from '../mavejs/base/action/action.component';
import { DeleteModalComponent } from '../mavejs/ui/delete-modal/delete-modal.component';
import { ModalComponent } from '../mavejs/ui/modal/modal.component';
import { TextboxComponent } from '../mavejs/form/textbox/textbox.component';
import { ButtonComponent } from '../mavejs/form/button/button.component';
import { LoaderComponent } from '../mavejs/http/loader/loader.component';
import { EmptyComponent } from '../mavejs/base/empty/empty.component';
import { CardComponent } from '../mavejs/base/card/card.component';
import { InputDirective } from '../mavejs/form/input.directive';

@NgModule({
  declarations: [
    CommentComponent,
    CommentModalComponent,
    CommentOverviewComponent
  ],
  imports: [
    // Components
    ActionComponent,
    DeleteModalComponent,
    ModalComponent,
    TextboxComponent,
    ButtonComponent,
    LoaderComponent,
    EmptyComponent,
    CardComponent,
    InputDirective,

    // Modules
    BadgeModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    CommentComponent,
    CommentModalComponent,
    CommentOverviewComponent
  ]
})
export class CommentModule { }
