import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { SyncService } from 'src/app/sync/services/sync.service';

@Component({
  selector: 'ganal-refresh-modal',
  templateUrl: './refresh-modal.component.html',
  styleUrls: ['./refresh-modal.component.scss']
})
export class RefreshModalComponent implements OnInit {
  @ViewChild('refreshModal') refreshModal!: ModalComponent;

  refreshAccountIsLoading: boolean = false;
  accountId!: number;
  accountName!: string;

  constructor(private syncService: SyncService) { }

  ngOnInit(): void {
  }

  refreshAccount() {
    this.refreshAccountIsLoading = true;
    this.syncService.syncAllDataForAccount(this.accountId).subscribe(() => {
      this.refreshAccountIsLoading = false;
      this.refreshModal.closeModal();
    })
  }

  setAccount(accountId: number, accountName: string) {
    this.accountId = accountId;
    this.accountName = accountName;
  }

  openModal() {
    this.refreshModal.openModal();
  }

}
