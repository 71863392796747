import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { IErrorMessage } from '../../interfaces/error-message';
import { ValidationComponent } from '../validation/validation.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'mave-textbox',
  imports: [ValidationComponent, NgIf, NgClass],
  standalone: true,
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css']
})
export class TextboxComponent implements OnInit {
  @Input() label!: string;
  @Input() name!: string;
  @Input() description: string = '';
  @Input() control!: AbstractControl | null;
  @Input() errorMessage!: IErrorMessage;

  constructor() { }

  ngOnInit(): void {
  }

  get hasErrors() {
    return this.control?.touched && this.control.errors;
  }

}
