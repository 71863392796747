import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphFormatterBuilder } from './graph-formatter-builder';
import { NoopGraphValueParser } from './graph-value-parser';
import { IAccount } from 'src/app/account/interfaces/account';

export class GraphOptions {

  public static getAccountGraphOptions(hasLabels: boolean, account: IAccount): any {
    return {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: true,
          font: {
            family: 'Inter'
          },
          callbacks: {
            label: function (context: any) {
              return GraphFormatterBuilder.get(context.parsed.y, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format();
            }
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          display: hasLabels,
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              day: 'eee d LLL'
            },
            unit: 'day'
          },
        },
        y: {
          display: false,
          beginAtZero: false,
          min: 0
        }
      }
    };
  }

  public static getCampaignGraphOptions(hasLabels: boolean, account: IAccount): any {
    return {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: true,
          font: {
            family: 'Inter'
          },
          callbacks: {
            label: function (context: any) {
              return GraphFormatterBuilder.get(context.parsed.y, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format();
            }
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          display: hasLabels,
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              day: 'eee d LLL'
            },
            unit: 'day'
          },
        },
        y: {
          display: false,
          beginAtZero: false,
          min: 0
        }
      }
    };
  }

  public static getBigGraphOptions(account: IAccount): any {
    let bigGraph = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: false,
          font: {
            family: 'Inter'
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          min: 0
        }
      }
    };

    return bigGraph;
  }

  public static getSmallGraphOptions(): any {
    let smallGraph = {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          formatter: function(value: any, context: any) {
            return '';
          }
        }
      },
      parsing: {},
      scales: {
        x: {
          display: false,
          type: 'time',
          time: {
            unit: 'day'
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          max: 100,
          min: 0
        }
      }
    };

    return smallGraph;
  }


}
