import { IErrorMessage } from "../interfaces/error-message";

export default class ErrorMessageBuilder {

  errors!: any;
  name: string;
  errorMessage: IErrorMessage

  messages: any = {
    'email': this.getMailMessage,
    'required': this.getRequiredMessage,
    'minlength': this.getMinLengthMessage,
    'min': this.getMinMessage,
    'maxlength': this.getMaxLengthMessage,
    'pattern': this.getPatternMessage,
    'budgetOvershoot': this.getBudgetOvershootMessage,
  }

  constructor(errors: any, name: string, errorMessage: IErrorMessage) {
    this.errors = errors;
    this.name = name;
    this.errorMessage = errorMessage;
  }

  getBudgetOvershootMessage(options: any, name: string): any {
    return 'Your budget change is very drastic.'
  }

  getMailMessage(options: any, name: string): any {
    return 'Should be a valid email'
  }

  getRequiredMessage(options: any, name: string): any {
    return name.charAt(0).toUpperCase() + name.slice(1) + ' is required'
  }

  getMinLengthMessage(options: any, name: string) {
    return 'This should have minimum ' + options.requiredLength + ' characters';
  }

  getMinMessage(options: any, name: string) {
    return 'The ' + name + ' should atleast be ' + options.min;
  }

  getMaxLengthMessage(options: any, name: string) {
    return 'This should have maximum ' + options.requiredLength + ' characters';
  }

  getPatternMessage(options: any, name: string) {
    const messages: any = {
      '/^-?\\d*[.,]?\\d{0,2}$/': 'This field should be a valid decimal'
    }

    if (messages[options.requiredPattern] === undefined) {
      return 'This field is not correctly formatted'
    }

    return messages[options.requiredPattern];
  }

  build(): any {
    if (this.errors === null || this.errors === undefined) {
      return ''
    }

    for (const [validation, options] of Object.entries(this.errors)) {
      if (this.errorMessage && this.errorMessage.type === validation) {
        return this.errorMessage.message;
      }

      if (this.messages[validation] === undefined) {
        return 'This field is not valid'
      }

      return this.messages[validation](options, this.name);
    }
  }

}
