import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private router: Router) { }

  

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.isExpired()) {
      this.auth.clearSessionToken();
    }

    if (!this.auth.hasAuthorizationToken()) {
      return next.handle(req).pipe(
        catchError((error: any) => {
          if (req.method === "OPTIONS") {
            return throwError(() => error);
          }

          if (error.status === HttpStatusCode.InternalServerError) {
            if (this.router.url !== "/error/500") {
              this.router.navigateByUrl("/error/500");
              return throwError(() => error);
            }
          }

          if (this.router.url !== "/user/login") {
            this.router.navigateByUrl("/user/login");
            return throwError(() => error);
          }

          return throwError(() => error);
        })
      );
    }

    const authToken = this.auth.getAuthorizationToken();

    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });

    return next.handle(authReq);
  }

}

